import React from "react";
import UserSmallProps from "components/user/usersmallprops";

export const COLUMNS = [
  {
    Header: "Created By",
    accessor: (e) => {return e.createD_BY_USER !== null ? e.createD_BY_USER.firstName + " " + e.createD_BY_USER.lastName : "";},
    Cell: (e) => <UserSmallProps userInfo={e.row.original.createD_BY_USER} alt={e.row.original.createD_BY} />,
  },

  {
    Header: "Title",
    accessor: "title",
    Cell: (e) => <div style={{ width: "150px" }}>{e.value}</div>,
  },

  {
    Header: "Link",
    accessor: "url",
    accessor: (e) => {return e.parenT_TYPE !== null && e.parenT_ID !== null ? e.parenT_TYPE + " " + e.parenT_ID : "";},
    Cell: (e) => (<a target="_blank" rel="noopener noreferrer" href={e.row.original.url}>{e.value}</a>),
  },

  {
    Header: "Date",
    accessor: "date",
    Cell: (e) => <div style={{ width: "100px" }}>{e.value.substring(0, 10)}</div>,
  },

  {
    Header: () => <div> Details </div>,
    accessor: "details",
    Cell: (e) => <div style={{ width: "300px" }}> {e.value.substring(0, 250)}</div>,
  },

  {
    Header: "Type",
    accessor: "type",
  },
];
