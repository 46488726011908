import React, { useMemo } from "react";
import { useTable, useFilters, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { ColumnFilter } from "components/table/ColumnFilter";
import { GlobalFilter } from "components/table/GlobalFilter";
import "react-responsive-modal/styles.css";
import "./modaltable.css"
import "components/styles/button-styles.css";

export const ModalTable = (props) => {
  const columns = useMemo(() => props.COLUMNS, [props.COLUMNS]);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
      sortType: "basic",
    }),
    []
  );

  let data = props.data;

  const { getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, canPreviousPage, canNextPage, pageOptions, prepareRow, state, gotoPage, pageCount, setPageSize, setGlobalFilter } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn,
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter } = state;

  const { pageIndex, pageSize } = state;

  return (
    <>
      {props.loading && (
        <div style={{ color: `#0EBFE9` }}>
          <p style={{ textAlign: "center", fontSize: "2rem" }}>Loading Data...</p>
        </div>
      )}

      {props.error && (
        <div style={{ color: `red` }}>
          <p style={{ textAlign: "center", fontSize: "2rem" }}>{props.error.name}: {props.error.message}</p>
        </div>
      )}

      <div style={{ marginTop: "-20px", border: "1px solid lightgrey" }}>
        <div className="flexy">
          <button className="tablebluebutton" type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{"<<"}</button>
          <button className="tablebluebutton" type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
          <button className="tablebluebutton" type="button" onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
          <button className="tablebluebutton" type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{">>"}</button>
          <span style={{whiteSpace: "nowrap"}}>Page <strong style={{ fontSize: "13px" }}>{pageIndex + 1} of {pageOptions.length}</strong></span>
          <span style={{marginLeft: "10px"}}>
          <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
            {[10, 25, 50, 100, 250, 500, 1000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>Show {pageSize}</option>
            ))}
          </select>
          </span>
          <span style={{marginLeft: "10px"}}>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </span>
        </div>
        <table className="aTable aTabletd aTableth aTabletrnth aTabletr aTablefootth aTabletfoot, aTabletfoottd" {...getTableProps()} style={{ margin: "auto" }}>
          <thead style={{ position: "sticky", top: "0" }}>
            {headerGroups.map((headerGroup) => (
              <tr style={{ whiteSpace: "nowrap" }} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      <div>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</div>
                    </div>
                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} style={{ fontSize: ".95rem", padding: "2px" }}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
