import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { axiosWithAuth } from "utils/axiosWithAuth";
import image from "../../images/apollologin.png"

const Login = (props) => {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const site = urlParams.get("page");

  const [userData, setUserData] = useState({
    username: "",
    password: "",
    isFetching: false,
  });
  const loginFormSchema = yup.object().shape({
    username: yup.string().required("Please enter your username!"),
    password: yup.string().required("Please enter your password!"),
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const [errorz, setError] = useState("");

  const formValidation = (e) => {
    yup
      .reach(loginFormSchema, e.target.name)
      .validate(e.target.value)
      .then((valid) => {
        setErrors({ ...errors, [e.target.name]: "" });
      })
      .catch((err) => {
        setErrors({
          ...errors,
          [e.target.name]: err.errors[0],
        });
      });
  };

  const inputChange = (e) => {
    e.persist();
    const newFormData = {
      ...userData,
      [e.target.name]: e.target.value,
    };
    formValidation(e);
    setUserData(newFormData);
  };

  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    loginFormSchema.isValid(userData).then((valid) => {
      setButtonDisabled(!valid);
    });
  }, [userData, loginFormSchema]);

  const loginSubmit = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .post("/api/users/authenticate", userData)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("user", res.data.usertype);
        localStorage.setItem("first", res.data.firstName);
        localStorage.setItem("last", res.data.lastName);
        localStorage.setItem("reports", res.data.viewInvoiceReport);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("debug", res.data.isDebug);
        localStorage.setItem("initials", res.data.initials);
        localStorage.setItem("salesgroup", res.data.salesGroup);
        localStorage.setItem("dashboardid", res.data.dashboardId);
        localStorage.setItem("hassalesbonus", res.data.hasSalesBonus);
        if (res.status === 200) {
          if (site !== null) {
            window.location = `${queryString.substring(6)}`;
          } else {
            window.location = "/";
          }
        }
      })
      .catch((errorz) => {
        setError(errorz);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError();
    }, 4000); //error message will display for 4 seconds

    return () => clearTimeout(timeout); //after 4 seconds the error message will be cleared
  }, [errorz]);

  return (
    <>

      <div style={{ backgroundImage:`url(${image})`, height: "100vh", backgroundSize: "cover", WebkitFilter: "blur(2px)", backgroundColor:"#274460"}} >   </div>
    
        <div style={{  borderRadius: "10px", backgroundColor:"white", width:"320px", height:"440px", position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', paddingTop: "20px", boxShadow: "10px -10px 70px 50px rgba(0, 0, 0, 0.22)"}}>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}} >
           <img style={{height: "72px", width: "72px"}} src={require("../../images/icon256.png")} alt={"icon"}/>{" "}
           <img style={{width: "50%"}} src={require("../../images/apollo.png")} alt={"apollologo"}/>{" "}
           <img style={{width: "20%"}} src={require("../../images/crm.png")} alt={"crmlogo"}/>{" "}
          </div>

          <div style={{marginTop: "30px"}}>
           <form style={{display: "flex", flexDirection: "column" }} onSubmit={loginSubmit}>
            <label style={{marginBottom: "-3px", fontSize:"10px", marginLeft:"40px"}} htmlFor="username">Username or Email:</label>
            <input style={{textAlign:"center", width: "240px", margin:"auto", height: "40px"}} type="text" id="username" autoFocus  name="username" value={userData.username} onChange={inputChange} required />
            {errors.username.length > 0 ? <p style={{color: "red", fontSize: "10px", marginBottom: "-15px", marginLeft:"40px"}}>{errors.username}</p> : null}
        

            <label style={{marginBottom: "-3px",fontSize:"10px", marginLeft:"40px", marginTop: "26px"}} htmlFor="password">Password:</label>
            <input style={{textAlign:"center", width: "240px", margin:"auto", height: "40px"}} type="password" id="password"  name="password" value={userData.password} onChange={inputChange} required/>
            {errors.password.length > 0 ? <p style={{color: "red", fontSize: "10px", marginBottom: "-15px", marginLeft:"40px"}}>{errors.password}</p> : null}
            <button style={{ width:"80px", height: "34px", backgroundColor: "#38a6ff", margin: "1px", border:"none", color:"white", marginLeft:"200px", marginTop:"40px", fontSize: "12px", fontWeight: "bold"}} disabled={buttonDisabled} type="submit">LOG IN</button>
           
            {errorz && errorz.message === "Request failed with status code 400" && (
            <div style={{ color: `red` }}>
              <h1 style={{fontSize:"18px", marginLeft:"40px", marginTop: "4px"}}> Invalid Password or Username </h1>
            </div>
            )}

            {errorz && errorz.message !== "Request failed with status code 400" && (
            <div style={{ color: `red` }}>
              <h1 style={{fontSize:"18px", marginLeft:"40px", marginTop: "4px"}}> {errorz.message} </h1>
            </div>
            )}
        </form>
        </div>
      </div>
    </>
  );
};

export default Login;

