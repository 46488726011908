import React, { useState, useEffect } from "react";
import { axiosWithAuth } from "utils/axiosWithAuth";
import { Card, Col, Row, Container } from "react-bootstrap";
import "./homedashboard.css";

const OpportunityFunnel = () => {
  const salesgroup = window.localStorage.getItem("salesgroup");
  const userid = window.localStorage.getItem("id");

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const [salesGroup, setSalesGroup] = useState({
    dropDown: ((salesgroup === "HVAC_IND-D") && (userid === "37") ? "HVAC-D" : (salesgroup === "HVAC_IND-D") && (userid === "38") ? "INDUSTRIAL-D" : salesgroup),
  });

  let territoryparam = (salesGroup.dropDown === "DOMESTIC") || (salesGroup.dropDown === "HVAC_IND-D") || (salesGroup.dropDown === "HVAC-D") || (salesGroup.dropDown === "INDUSTRIAL-D") || (salesGroup.dropDown === "AUTOMOTIVE-D") ? "D" : salesGroup.dropDown === "INTERNATIONAL" ? "I" : "ALL"

  let marketparam = (salesGroup.dropDown === "DOMESTIC") || (salesGroup.dropDown === "INTERNATIONAL") ? "ALL"  : (salesGroup.dropDown === "HVAC-D") ? "HVAC" :  (salesGroup.dropDown === "INDUSTRIAL-D") ? "INDUSTRIAL" : (salesGroup.dropDown === "AUTOMOTIVE-D") ? "AUTOMOTIVE" : salesGroup.dropDown === "HVAC_IND-D" ? "HVAC_IND" : salesGroup.dropDown === "PIPEFREEZE" ? "PIPEFREEZE" : "ALL"

  useEffect(() => {
    setLoading(true);
    axiosWithAuth()
      .get(`/api/dashboard/opportunity_funnel/${salesGroup.dropDown}`)
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((err) => console.log(err), setLoading(false));
  }, [salesGroup]);

  return (
    <>
      <Card style={{ width: "480px", height: "400px" }} className="shadow-sm">
        <Row>

        <h3 style={{ color: "black", textAlign: "center", fontSize: "1.5rem", marginTop: "6px" }}>{data.title}</h3>
          <h4 style={{ color: "black", textAlign: "center", fontSize: "1rem", marginTop: "-8px" }}>{data.description}</h4>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <label style={{ marginLeft: "5px" }}>Market:</label>
            <select style={{ marginLeft: "5px", width: "130px" }} value={salesGroup.dropDown} id="dropDown" name="dropDown" onChange={(e) => setSalesGroup({ ...salesGroup, dropDown: e.target.value })}>
              <option value="ALL">All</option>
              <option value="DOMESTIC">Domestic</option>
              <option value="INTERNATIONAL">International</option>
              {/* <option value="HVAC_IND-D">HVAC_IND-D</option> */}
              <option value="HVAC-D">HVAC-D</option>
              <option value="INDUSTRIAL-D">INDUSTRIAL-D</option>
              <option value="AUTOMOTIVE-D">Automotive-D</option>
              <option value="PIPEFREEZE">PipeFreeze</option>
            </select>
          </div>

          <div hidden={loading} style={{ height: "320px", display: "flex", justifyContent: "center" }}>
            <div style={{ marginTop: "1%" }}>
              <div className="containerFunnel">
                <img style={{ height: "60px", width: "474px", display: "flex", alignItems: "center", alignContent: "center", justifyItems: "center", justifyContent: "center", margin: "0 auto" }} src={require("../../../images/funnel/OpportunityFunnal1.png")} alt={"funnel1"} />
                <div className="centeredFunnel">
                <a style={{color: "white"}} href={"allopportunities/?stage=PROSPECT&status=Open"+(territoryparam !== "ALL" ? "&territory="+territoryparam : "")+(marketparam !== "ALL" ? "&market="+marketparam : "") }>
                  <div style={{color: "white"}}>Prospect ({data.prospectCount})</div>
                  <div style={{color: "white"}}>${(Math.round(data.prospectAmount/1000))}K</div>
                  </a>
                </div>
              </div>

              <div className="containerFunnel">
                <img style={{ height: "60px", width: "474px", display: "flex", alignItems: "center", alignContent: "center", justifyItems: "center", justifyContent: "center", margin: "0 auto" }} src={require("../../../images/funnel/OpportunityFunnal2.png")} alt={"funnel2"} />

                <div className="centeredFunnel">
                <a style={{color: "white"}} href={"allopportunities/?stage=ASSESSMENT&status=Open"+(territoryparam !== "ALL" ? "&territory="+territoryparam : "")+(marketparam !== "ALL" ? "&market="+marketparam : "")}>
                <div style={{color: "white"}}>Assessment ({data.assessmentCount})</div>
                  <div style={{color: "white"}}>${(Math.round(data.assessmentAmount/1000))}K</div>
                  </a>
                </div>
              </div>

              <div className="containerFunnel">
                <img style={{ height: "60px", width: "474px", display: "flex", alignItems: "center", alignContent: "center", justifyItems: "center", justifyContent: "center", margin: "0 auto" }} src={require("../../../images/funnel/OpportunityFunnal3.png")} alt={"funnel3"} />
                <div className="centeredFunnel">
                <a style={{color: "white"}} href={"allopportunities/?stage=PREPARATION&status=Open"+(territoryparam !== "ALL" ? "&territory="+territoryparam : "")+(marketparam !== "ALL" ? "&market="+marketparam : "")}>
                <div style={{color: "white"}}>Preparation ({data.preparationCount})</div>
                  <div style={{color: "white"}}>${(Math.round(data.preparationAmount/1000))}K</div>
                  </a>
                </div>
              </div>

              <div className="containerFunnel">
                <img style={{ height: "60px", width: "474px", display: "flex", alignItems: "center", alignContent: "center", justifyItems: "center", justifyContent: "center", margin: "0 auto" }} src={require("../../../images/funnel/OpportunityFunnal4.png")} alt={"funnel4"} />

                <div className="centeredFunnel">
                <a style={{color: "white"}} href={"allopportunities/?stage=SOLUTION&status=Open"+(territoryparam !== "ALL" ? "&territory="+territoryparam : "")+(marketparam !== "ALL" ? "&market="+marketparam : "")}>
                <div style={{color: "white"}}>Solution ({data.solutionCount})</div>
                  <div style={{color: "white"}}>${(Math.round(data.solutionAmount/1000))}K</div>
                  </a>
                </div>
              </div>


              <div className="containerFunnel">
                <img style={{ height: "60px", width: "474px", display: "flex", alignItems: "center", alignContent: "center", justifyItems: "center", justifyContent: "center", margin: "0 auto" }} src={require("../../../images/funnel/OpportunityFunnal5.png")} alt={"funnel5"} />

                <div className="centeredFunnel">
                <a style={{color: "white"}} href={"allopportunities/?stage=CLOSE&status=Open"+(territoryparam !== "ALL" ? "&territory="+territoryparam : "")+(marketparam !== "ALL" ? "&market="+marketparam : "")}>
                <div style={{color: "white"}}>Close ({data.closeCount})</div>
                  <div style={{color: "white"}}>${(Math.round(data.closeAmount/1000))}K</div>
                  </a>
                </div>
              </div>

            </div>

        
          </div>
        </Row>
      </Card>
    </>
  );
};

export default OpportunityFunnel;
