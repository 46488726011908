import React, { useState, useEffect } from "react";
import { axiosWithAuth } from "utils/axiosWithAuth";
import { Card, Row, Form } from "react-bootstrap";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { ModalTable } from "../tables/ModalTable";
import { COLUMNS } from "./globalSearchColumns";
import "components/styles/button-styles.css";

const GlobalSearch = (props) => {
  const [data, setData] = useState([]);
  const [searchResult, setSearchResult] = useState("");
  const [error, setError] = useState("");
  const [hideContent, setHideContent] = useState(true);

  const [openSearch, setOpenSearch] = useState(false);
  const onOpenSearchModal = () => setOpenSearch(true);
  const onCloseSearchModal = () => setOpenSearch(false);

  const ModalStyles = {
    modal: {
      borderRadius: "25px",
      border: "4px solid lightblue",
      padding: "20px",
      fade: "false",
      maxWidth: "1200px",
      width: "1200px",
    },
  };

  const setSearch = (e) => {
    setSearchResult(e.target.value);
    setError();
  };

  const loadSearch = (e) => {
    axiosWithAuth()
      .get(`/api/search?search=${searchResult}`)
      .then((res) => {
        setData(res.data);
        if (searchResult !== "" || null || undefined || searchResult.length <= 0) {
          setHideContent(false);
        } else setHideContent(true);
      })
      .catch((error) => {
        setError(error);
        if (searchResult !== "" && null && undefined && searchResult.length <= 0) {
          setHideContent(false);
        } else setHideContent(true);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError();
    }, 4000);

    return () => clearTimeout(timeout);
  }, [error]);

  var input = document.getElementById("enterinput");

  useEffect(() => {
    if (input) {
      const listener = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          event.preventDefault();
          loadSearch();
        }
      };
      input.addEventListener("keydown", listener);
      return () => {
        input.removeEventListener("keydown", listener);
      };
    }
  }, [searchResult]);

  function MouseOver(event) {
    event.target.style.opacity = ".7";
  }
  function MouseOut(event) {
    event.target.style.background = "white";
    event.target.style.opacity = "1";
  }

  return (
    <>
      <button className="buttonhover" type="button" onMouseOver={MouseOver} onMouseOut={MouseOut} onClick={onOpenSearchModal} style={{ height: "40px", width: "40px", marginTop: "10px", border: "none", background: "transparent" }}>🔍</button>

      <Modal styles={ModalStyles} open={openSearch} onClose={onCloseSearchModal} closeOnOverlayClick={true} center>
        <Card>
          <Card.Header>
            <h1 style={{ textAlign: "center" }}>Global Search</h1>
          </Card.Header>

          <div>
            <Form>
              <Card.Body>
                <div>
                  <Row>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div>
                        <label>Search Site:</label>
                      </div>
                      <div style={{ display: "flex", marginLeft: "5px" }}>
                        <input id="enterinput" onChange={(e) => setSearch(e)} style={{ borderLeft: "5px solid red", width: "500px" }} />
                        <button id="BtnClk" style={{ width: "100px", marginLeft: "10px" }} type="button" onClick={loadSearch}>Search</button>
                      </div>
                    </div>
                  </Row>
                </div>

                <div hidden={hideContent} style={{ marginTop: "30px" }}>
                  <ModalTable COLUMNS={COLUMNS} data={data} loading={false} error={error} />
                </div>
              </Card.Body>
            </Form>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default GlobalSearch;
