import React, { useState, useEffect } from "react";
import { axiosWithAuth } from "utils/axiosWithAuth";
import { VersionNumber } from "components/version/Version";
import { Badge } from "react-bootstrap";

const version = VersionNumber;

export const UnreadLeads = (NotificationNavTest) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosWithAuth()
      .get(`/api/notification/current`)
      .then((res) => {
        setLoading(false);
        setNotifications(res.data);
      })
      .then((res) => {})
      .catch((err) => console.log("Error", err));
  }, []);

  //Version will be updated every time backend version is updated to be same number
  return (
    <>
      <Badge bg="danger" pill style={{ color: "white", fontSize: ".7rem", position: "absolute", marginTop: "-14px", zIndex: 3 }}>
        {loading === true ? "-" : notifications.activeLeads}
      </Badge>
    </>
  );
};

export const UnreadTasks = (NotificationNavTest) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axiosWithAuth()
      .get(`/api/notification/current`)
      .then((res) => {
        setLoading(false);
        setNotifications(res.data);
      })
      .catch((err) => console.log("search not working", err));
  }, []);

  return (
    <>
      <Badge bg="danger" pill style={{ color: "white", fontSize: ".7rem", position: "absolute", marginTop: "-14px", zIndex: 3 }}>
        {loading === true ? "-" : notifications.activeTasks}
      </Badge>
    </>
  );
};

export const Version = (props) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosWithAuth()
      .get(`/api/notification/current`)
      .then((res) => {
        setLoading(false);
        setNotifications(res.data);
      })
      .then((res) => {})
      .catch((err) => console.log("Error", err));
  }, []);

  return <>{notifications.siteVersion !== undefined && version !== notifications.siteVersion ? <h1 style={{ color: "orange", fontSize: "2rem", textAlign: "center" }}>A new CRM version is available. Please close down your browser fully to enjoy the new features!</h1> : null}</>;
};
