import axios from "axios";
import { VersionNumber } from "components/version/Version";

export const axiosWithAuth = () => {
  const token = localStorage.getItem("token");

  return axios.create({
    baseURL: "/",
    headers: {
      Authorization: token, 
      Version: VersionNumber
    },
  });
};