import React, { useState, useEffect } from "react";
import { axiosWithAuth } from "utils/axiosWithAuth";

const UserSmallProps = (props) => {

  return <>
    <div style={{border: "0px solid black"}}>    
      {props.userInfo === null
      ?<div><img style={{width:'2em'}} src={'/profiles/default.png'}/>{props.alt}</div>      
      :<div style={{display:"flex"}}><a href="/allusers"  target="_blank"><img  style={{width:'2em'}}   src={props.userInfo.imageUrl}></img></a><span style={{paddingRight:"4px", paddingLeft:"2px", paddingTop:"2px"}}>{props.userInfo.firstName} {props.userInfo.lastName} </span></div>
      }
    </div>
  </>;
};

export default UserSmallProps;
