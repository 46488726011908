import React, { useState, useEffect } from "react";
import { axiosWithAuth } from "utils/axiosWithAuth";

const UserList = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axiosWithAuth()
      .get(`api/users/all`)
      .then((res) => {
        setUsers(
          res.data.filter((info) => {
            return info;
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <option value="">--None--</option>
      {users.map((users) => (
        <option key={users.username} value={users.username}>
          {users.firstName} {users.lastName}
        </option>
      ))}
    </>
  );
};

export default UserList;
