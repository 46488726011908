import React, { useState, useEffect } from "react";
import { axiosWithAuth } from "utils/axiosWithAuth";
import Linkify from "react-linkify";
import { Card } from "react-bootstrap";

const ReusableTaskFeed = () => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    axiosWithAuth()
      .get(`/api/feed`)
      .then((res) => {
        res.data.forEach((element) => {
          element.isCollapsed = true;
        });
        setComments(
          res.data.filter((comment) => {
            return comment;
          })
        );
      })
      .catch((err) => console.log("Not working", err));
  }, []);

  return (
    <>
      <div style={{ marginLeft: "8px", marginTop: "16px", marginRight: "8px" }}>
        <Card style={{ maxWidth: "444px" }}>
          <Card.Header style={{ textAlign: "center", fontSize: "2rem" }}>
            <h3><a href="/followfeed" target="_blank" rel="noopener noreferrer">Followed Tasks</a></h3>
          </Card.Header>

          {comments.slice(0, 30).map((comment, i) => (
            <Card key={i}>
              <div style={{ marginBottom: "10px", marginTop: "10px", paddingBottom: "24px", maxWidth: "440px", backgroundColor: "white", borderRadius: "4px", filter: "drop-shadow(-3px 3px 3px silver)" }} key={comment.id}>
                <div key={comment.id}>
                  <div style={{ display: "flex" }}>
                    <div>
                      <img style={{ marginLeft: "5px", marginTop: "5px", height: "50px" }} src={comment.useR_ICON_URL} alt="profilepic" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p style={{ marginLeft: "10px", marginTop: "10px", fontSize: "12px" }}>{comment.firsT_NAME + " " + comment.lasT_NAME} {comment.action}: {comment.objecT_TYPE} {comment.objecT_ID} Date: {comment.timestamp.substring(5, 10)}-{comment.timestamp.substring(0, 4)} {comment.timestamp.substring(11, 20)}</p>
                      <a target="_blank" href={comment.objecT_URL}><p style={{ marginLeft: "10px", marginTop: "-15px", fontSize: "16px", fontWeight: "bold" }}> {comment.name}</p></a>
                    </div>
                  </div>

                  <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>{decoratedText}</a>)}>
                    <p style={{ marginLeft: "10px", marginRight: "10px", flexWrap: "wrap", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{comment.description}</p>
                  </Linkify>
                  <div></div>
                </div>
              </div>
            </Card>
          ))}
        </Card>
      </div>
    </>
  );
};

export default ReusableTaskFeed;
