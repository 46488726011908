import React from "react";
import { Card, Col, Row, Container } from "react-bootstrap";
import AccountClassificationPie from "./AccountClassificationPie";
import OpportunityFunnel from "./OpportunityFunnel";
// import AccountTypePie from "./AccountTypePie";

const HomeDashboard = () => {
  return (
    <>
      <div style={{ marginTop: "16px", display: "flex", justifyContent: "space-evenly" }}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={6}>
            <AccountClassificationPie />
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={6}>
            <OpportunityFunnel />
          </Col>
        </Row>
      </div>
      {/* <div style={{ marginTop: "16px", display: "flex", justifyContent: "space-evenly" }}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={6}>
            <AccountTypePie />
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={6}>
            <AccountTypePie />
          </Col>
        </Row>
      </div> */}
    </>
  );
};

export default HomeDashboard;
