import React from "react";
import { Link } from "react-router-dom";
import "./directorycss.css";
import Masq from "../login/Masq";
import { Card, Col, Row, Container } from "react-bootstrap";
import { NavMenu } from "components/NavMenu";
import ReusableTaskFeed from "components/feed/ReusableTaskFeed";
import HomeDashboard from "components/dashboard/homedashboard/HomeDashboard";

const Home = (res) => {
  const id = window.localStorage.getItem("id");
  const userId = window.localStorage.getItem("user");
  const firstName = window.localStorage.getItem("first");
  const lastName = window.localStorage.getItem("last");
  const reports = window.localStorage.getItem("reports");
  const masq = window.localStorage.getItem("masq");
  const username = window.localStorage.getItem("username");
  const dashboardid = window.localStorage.getItem("dashboardid");

  var hideFont = false

  let screenWidth = window.matchMedia("all and (max-width: 767px)");
  if (screenWidth.matches) {
    hideFont = true
  }

  return (
    <>
      <div style={{ backgroundColor: "#d9eeff", minHeight: "100vh" }}>
        <NavMenu />

        <div>
          <div>
            <div style={{marginTop: "1%"}}>
              <img style={{display: "flex", alignItems: "center", alignContent: "center", justifyItems: "center", justifyContent: "center", margin: "0 auto"}} src={require("../../images/apollo300.png")} alt={"company"} />
            </div>
          </div>

          {id !== "41" ? (
            <Container className="pb-2" style={{ textAlign: "center" }}>
              <p className="titlefont">Welcome {firstName} {lastName}</p>
            </Container>
          ) : null}

          {id === "41" ? (
            <Container className="pt-2" style={{ textAlign: "center" }}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                  <input type="image" style={{ height: "60px", width: "120px" }} src={require("../../images/NIN.jpg")} alt={"company"} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  {/* <a href="https://www.youtube.com/watch?v=mKyxnbjC8KU&ab_channel=Dizzyberkowitz">
                    <link href="https://fonts.googleapis.com/css?family=Creepster|Source+Sans+Pro|Nosifer" rel="stylesheet" />
                    <p style={{ whiteSpace: "nowrap" }} className={hideFont === false ? "blood" : ""}>Welcome Mr. {lastName}</p>
                  </a> */}
      
                    <p style={{ whiteSpace: "nowrap" }} className="titlefont">Welcome Mr. {lastName}</p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                  <input type="image" style={{ height: "60px", width: "120px" }} src={require("../../images/NIN.jpg")} alt={"company"} />
                </Col>
              </Row>
            </Container>
          ) : null}

          {id === "41" ? (
            <Container className="pt-2 pb-2" style={{ textAlign: "center" }}>
              <a href="/salespivot">
                <input type="image" style={{ height: "90px", width: "90px" }} src={require("../../images/tooleye.png")} alt={"company"} />
              </a>
              <a href="/salespivot">
                <input type="image" style={{ height: "108px", width: "216px" }} src={require("../../images/tool.png")} alt={"company"} />
              </a>
              <a href="/salespivot">
                <input type="image" style={{ height: "90px", width: "90px" }} src={require("../../images/tooleyetwo.png")} alt={"company"} />
              </a>
            </Container>
          ) : null}

          <div className="d-lg-flex">
            <Col className="d-none d-lg-block" xs={12} sm={12} md={12} lg={7} xl={7} xxl={8}>


            {(dashboardid !== "0") && (dashboardid !== 0) && (dashboardid !== null) && (dashboardid !== undefined) && (dashboardid !== "") ? (
                  <HomeDashboard /> 
                      ) : null}
          

              {id === "1" || id === "46" ? (
                <Container className="pt-3">
                  <Card className="shadow-sm">
                    <Card.Header style={{ textAlign: "center", fontSize: "2rem" }}>
                      <h3>Test</h3>
                    </Card.Header>

                    <Row>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link style={{ whiteSpace: "nowrap" }} to={"/commissions"}>Commissions</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link style={{ whiteSpace: "nowrap" }} to={"/admintools"}>Admin Tools</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link style={{ whiteSpace: "nowrap" }} to={"/forecastcontrolpanel/2024"}>Forecast Control Panel</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link style={{ whiteSpace: "nowrap" }} to={"/forecast/alltodo"}>Forecast All Groups</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link style={{ whiteSpace: "nowrap" }} to={"/grouplist"}>Group List</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link style={{ whiteSpace: "nowrap" }} to={"/notificationlog"}>Notification Log</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link style={{ whiteSpace: "nowrap" }} to={"/customersalesbyregion"}>Customer Sales By Region</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link style={{ whiteSpace: "nowrap" }} to={"/unprocessedcontactdata"}>Unprocessed Form Data</Link>
                      </Col>

                      <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link style={{ whiteSpace: "nowrap" }} to={"/allcontactformdata"}>All Contact Form Data</Link>
                      </Col>
                    </Row>
                  </Card>
                </Container>
              ) : null}

              <Container className="mt-3">
                <Card className="shadow-sm">
                  <Card.Header style={{ textAlign: "center" }}>
                    <h3>Tasks</h3>
                  </Card.Header>

                  <Row>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link style={{ whiteSpace: "nowrap" }} to={"/usertasks"}>Your Tasks</Link>
                    </Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link style={{ whiteSpace: "nowrap" }} to={"/createdtasks"}>Created Tasks</Link>
                    </Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link style={{ whiteSpace: "nowrap" }} to={"/followfeed"}>Followed Tasks</Link>
                    </Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}></Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link style={{ whiteSpace: "nowrap" }} to={"/viewtasks"}>All Tasks</Link>
                    </Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}></Col>
                  </Row>
                </Card>
              </Container>

              <Container className="mt-3">
                <Card className="shadow-sm">
                  <Card.Header style={{ textAlign: "center" }}>
                    <h3>Leads</h3>
                  </Card.Header>

                  <Row>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/usersleads"} style={{ whiteSpace: "nowrap" }}>Your Leads</Link>
                    </Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/leads"} style={{ whiteSpace: "nowrap" }}>All Leads</Link>
                    </Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/historic_crm_leads"} style={{ whiteSpace: "nowrap" }}>All Historic Leads</Link>
                    </Col>
                  </Row>
                </Card>
              </Container>

              <Container className="mt-3">
                <Card className="shadow-sm">
                  <Card.Header style={{ textAlign: "center" }}>
                    <h3>Opportunities</h3>
                  </Card.Header>

                  <Row>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/usersopportunities"} style={{ whiteSpace: "nowrap" }}>Your Opportunities</Link>
                    </Col>
                    <Col className="p-1 d-none d-xl-block" xl={6} xxl={4} style={{ textAlign: "center" }}></Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/allopportunities"} style={{ whiteSpace: "nowrap" }}>All Opportunities</Link>
                    </Col>
                  </Row>
                </Card>
              </Container>

              <Container className="mt-3">
                <Card className="shadow-sm">
                  <Card.Header style={{ textAlign: "center" }}>
                    <h3>Accounts</h3>
                  </Card.Header>
                  <Row>
                    <Col className="p-1" xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                      <Link to={"/accounts"} style={{ whiteSpace: "nowrap" }}>Accounts</Link>
                    </Col>
                  </Row>
                </Card>
              </Container>

              {reports === "true" ? (
                <Container className="mt-3">
                  <Card className="shadow-sm">
                    <Card.Header style={{ textAlign: "center" }}>
                      <h3>Reports</h3>
                    </Card.Header>

                    <Row>
                      {userId === "ADMIN" || userId === "MARKETING" || userId === "OPERATIONS" || userId === "MANAGEMENT" || userId === "MIS" ? (
                        <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                          <Link to="/customerinvoicereport" style={{ whiteSpace: "nowrap" }}>Customer Invoice History</Link>
                        </Col>
                      ) : null}
                      {userId === "SALES" ? (
                        <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                          <Link to="/salescustomerinvoicereport" style={{ whiteSpace: "nowrap" }}>Customer Invoice History</Link>
                        </Col>
                      ) : null}
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link to="/marketreport" style={{ whiteSpace: "nowrap" }}>Sales by Market</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link to="/productreport" style={{ whiteSpace: "nowrap" }}>Product Sales History</Link>
                      </Col>
                      <Col className="p-1" lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}></Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link to="/salespivot" style={{ whiteSpace: "nowrap" }}>Sales Pivot</Link>
                      </Col>
                      <Col className="p-1" lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}></Col>
                    </Row>
                  </Card>
                </Container>
              ) : null}

              <Container className="mt-3">
                <Card className="shadow-sm">
                  <Card.Header style={{ textAlign: "center" }}>
                    <h3>Resources</h3>
                  </Card.Header>

                  <Row>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/viewcustomers"} style={{ whiteSpace: "nowrap" }}>Customers</Link>
                    </Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/parts"} style={{ whiteSpace: "nowrap" }}>Parts</Link>
                    </Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/customerorders"} style={{ whiteSpace: "nowrap" }}>Customer Orders</Link>
                    </Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/pricelists"} style={{ whiteSpace: "nowrap" }}>Standard Price Lists</Link>
                    </Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/privatelabels"} style={{ whiteSpace: "nowrap" }}>Private Label Price Lists</Link>
                    </Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/custompricing"} style={{ whiteSpace: "nowrap" }}>Custom Pricing</Link>
                    </Col>
                    {/* <Col className="p-1" lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}></Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/discountoverview"} style={{ whiteSpace: "nowrap" }}>International Discounts</Link>
                    </Col>
                    <Col className="p-1" lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}></Col> */}


                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/discountoverview"} style={{ whiteSpace: "nowrap" }}>International Discounts</Link>
                    </Col>
                    <Col className="p-1 d-none d-xl-block" xl={6} xxl={4} style={{ textAlign: "center" }}></Col>
                    <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={"/edi/orders"} style={{ whiteSpace: "nowrap" }}>EDI Orders</Link>
                    </Col>

                    {/* <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                      <Link to={`/forecast/todo/?user=username`} style={{ whiteSpace: "nowrap" }}>Forecasting To-Do</Link>
                    </Col> */}




                  </Row>
                </Card>
              </Container>

              {userId === "ADMIN" ? (
                <Container className="mt-3 pb-3">
                  <Card className="shadow-sm">
                    <Card.Header style={{ textAlign: "center" }}>
                      <h3>Users</h3>
                    </Card.Header>

                    <Row>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link to="/createuser" style={{ whiteSpace: "nowrap" }}>Create User</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link to="/edituser" style={{ whiteSpace: "nowrap" }}>Edit Passwords</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link to="/allusers" style={{ whiteSpace: "nowrap" }}>All Users</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}></Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}>
                        <Link to="/masterlog" style={{ whiteSpace: "nowrap" }}>Master Log</Link>
                      </Col>
                      <Col className="p-1" xs={12} sm={12} md={6} lg={6} xl={6} xxl={4} style={{ textAlign: "center" }}></Col>
                    </Row>
                  </Card>
                </Container>
              ) : null}

              {userId !== "ADMIN" ? (
                <Container className="mt-3 pb-3">
                  <Card className="shadow-sm">
                    <Card.Header style={{ textAlign: "center" }}>
                      <h3>User</h3>
                    </Card.Header>

                    <Row>
                      <Col className="p-1" xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                        <Link to="/changepassword" style={{ whiteSpace: "nowrap" }}>Change Password</Link>
                      </Col>
                    </Row>
                  </Card>
                </Container>
              ) : null}

              {id === "1" || masq === "true" ? (
                <Container className="mt-3 pb-3">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Masq />
                  </div>
                </Container>
              ) : null}
            </Col>

            <Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={4}>
              <ReusableTaskFeed />
            </Col>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;