import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { axiosWithAuth } from "utils/axiosWithAuth";
import UserList from "../forms/UserList";

const Masq = (props) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const site = urlParams.get("page");

  const masq = window.localStorage.getItem("masq");
  const id = window.localStorage.getItem("id");

  const [userData, setUserData] = useState({
    username: "",
    password: "",
    isFetching: false,
  });
  const loginFormSchema = yup.object().shape({
    username: yup.string().required("Please enter your username!"),
    password: yup.string().required("Please enter your password!"),
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const [errorz, setError] = useState("");

  const [Demerrorz, setDemError] = useState("");

  const formValidation = (e) => {
    yup
      .reach(loginFormSchema, e.target.name)
      .validate(e.target.value)
      .then((valid) => {
        setErrors({ ...errors, [e.target.name]: "" });
      })
      .catch((err) => {
        setErrors({
          ...errors,
          [e.target.name]: err.errors[0],
        });
      });
  };

  const inputChange = (e) => {
    e.persist();
    const newFormData = {
      ...userData,
      [e.target.name]: e.target.value,
    };
    formValidation(e);
    setUserData(newFormData);
  };

  const [buttonDisabled, setButtonDisabled] = useState(true);
  useEffect(() => {
    loginFormSchema.isValid(userData).then((valid) => {
      setButtonDisabled(!valid);
    });
  }, [userData, loginFormSchema]);

  const loginSubmit = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .post("/api/users/masquerade", userData)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("user", res.data.usertype);
        localStorage.setItem("first", res.data.firstName);
        localStorage.setItem("last", res.data.lastName);
        localStorage.setItem("reports", res.data.viewInvoiceReport);
        localStorage.setItem("masq", res.data.masquerade);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("debug", res.data.isDebug);
        localStorage.setItem("initials", res.data.initials);
        localStorage.setItem("salesgroup", res.data.salesGroup);
        localStorage.setItem("dashboardid", res.data.dashboardId);
        localStorage.setItem("hassalesbonus", res.data.hasSalesBonus);
        if (res.status === 200) {
          if (site !== null) {
            window.location = `${site}`;
          } else {
            window.location = "/";
          }
        }
      })

      .catch((errorz) => {
        setError(errorz);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError();
    }, 4000);

    return () => clearTimeout(timeout);
  }, [errorz]);

  const deMasq = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .post("api/users/demasque")
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("user", res.data.usertype);
        localStorage.setItem("first", res.data.firstName);
        localStorage.setItem("last", res.data.lastName);
        localStorage.setItem("reports", res.data.viewInvoiceReport);
        localStorage.setItem("masq", res.data.masquerade);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("debug", res.data.isDebug);
        localStorage.setItem("initials", res.data.initials);
        localStorage.setItem("salesgroup", res.data.salesGroup);
        localStorage.setItem("dashboardid", res.data.dashboardId);
        if (res.status === 200) {
          if (site !== null) {
            window.location = `${site}`;
          } else {
            window.location = "/";
          }
        }
      })

      .catch((errorz) => {
        setDemError(errorz);
      });
  };

  return (
    <>
      <div style={{ border: "1px solid lightgrey", width: "238px", boxShadow: "1px 3px 1px #9E9E9E", borderRadius: "5px", backgroundColor: "#F8F8F8" }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <p>shhh</p>
        </div>

        {id === "1" || masq === "false" ? (
          <form style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "-20px" }} onSubmit={loginSubmit}>
            <label style={{ marginBottom: "-3px" }} htmlFor="username">Username:</label>

            <select style={{ borderLeft: "5px solid red" }} type="text" id="username" name="username" onChange={inputChange} value={userData.username} required>
              <UserList />
            </select>

            {errors.username.length > 0 ? <p>{errors.username}</p> : null}

            <label style={{ marginBottom: "-3px", marginTop: "5px" }} htmlFor="password">Password:</label>
            <input autoComplete="off" style={{ textAlign: "center", margin: "1px", width: "180px" }} type="password" id="password" placeholder="password" name="password" value={userData.password} onChange={inputChange} required />
            {errors.password.length > 0 ? <p>{errors.password}</p> : null}
            <button style={{ width: "80px", height: "40px", backgroundColor: "lightblue", margin: "1px", marginTop: "10px" }} disabled={buttonDisabled} type="submit">Login</button>
            {errorz && errorz.message === "Request failed with status code 400" && (
              <div style={{ color: `red` }}>
                <h1> Invalid Password or Username </h1>
              </div>
            )}

            {errorz && errorz.message !== "Request failed with status code 400" && (
              <div style={{ color: `red` }}>
                <h1> {errorz.message} </h1>
              </div>
            )}
          </form>
        ) : null}
        <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
          {masq === "true" ? (
            <form>
              <button onClick={deMasq} style={{ width: "80px", height: "40px", backgroundColor: "lightblue" }} type="button">Demasq</button>
            </form>
          ) : null}
        </div>
      </div>

      {Demerrorz && (
        <div style={{ color: `red` }}>
          <h1>{Demerrorz.name}: {Demerrorz.message}</h1>
        </div>
      )}
    </>
  );
};

export default Masq;
