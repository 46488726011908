import React from 'react'

export const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <span>
            {/* Search:{' '} */}
            <input
                value={filterValue || ''}
                onChange={e => setFilter(e.target.value)}
                // className="ughhh"
                style={{   width: "108px",
                    bottom: "0"}}
            />
        </span>
    )
}