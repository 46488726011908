import React, { useState, useEffect } from "react";
import { axiosWithAuth } from "utils/axiosWithAuth";
import { Card, Row } from "react-bootstrap";
import { PieChart, Pie, Cell, Sector } from "recharts";

const AccountClassificationPie = () => {
  const salesgroup = window.localStorage.getItem("salesgroup");
  const userid = window.localStorage.getItem("id");

  var today = new Date();
  var yyyy = today.getFullYear();

  const [loading, setLoading] = useState(true);

  const [dataOne, setDataOne] = useState([]);
  const [dataTwo, setDataTwo] = useState({});
  //dataThree for index of hover, keep track of where hover is on pie
  const [dataThree, setDataThree] = useState({ index: 0 });
  const [salesGroup, setSalesGroup] = useState({
    dropDown: ((salesgroup === "HVAC_IND-D") && (userid === "37") ? "HVAC-D" : (salesgroup === "HVAC_IND-D") && (userid === "38") ? "INDUSTRIAL-D" : salesgroup),
  });

  const [graphError, setGraphError] = useState("");

  useEffect(() => {
    setLoading(true);
    axiosWithAuth()
      .get(`api/dashboard/account_classification/${yyyy}/${salesGroup.dropDown}/graph`)
      .then((res) => {
        setLoading(false);
        setDataOne(
          res.data.records.filter((info) => {
            return info;
          })
        );
        setDataTwo(res.data);
      })
      .catch((error) => {
        setGraphError(error);
        setLoading(false);
      });
  }, [salesGroup]);

  const renderCustomizedLabel = ({ x, y, name, cx, value }) => {
    const newVal = Math.round(value / 1000); //moving over three decimal places to show numbers in thousands
    let sum = dataOne.reduce(function(prev, current) {
      return prev + current.sales
    }, 0);
    return (
      <text hidden={((newVal / (sum / 1000)) * 100) < 10 ? true : false} x={x} y={y} fill="black" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        ${newVal}K {name}
      </text>
    );
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    const newVal = Math.round(value / 1000); //moving over three decimal places to show numbers in thousands

      let sum = dataOne.reduce(function(prev, current) {
    return prev + current.sales
  }, 0);

    return (
      <g>
 
        <a style={{color: "white"}} href={"accounts/?type="+(name.toUpperCase().replace(/[0-9()]/g, '') === "UNDEFINED" ? "N/A":name.toUpperCase().replace(/[0-9()]/g, ''))+((salesGroup.dropDown === "HVAC-D") ? "&market=HVAC": "")+((salesGroup.dropDown === "INDUSTRIAL-D") ? "&market=INDUSTRIAL": "")+((salesGroup.dropDown === "AUTOMOTIVE-D") ? "&market=AUTOMOTIVE": "")+((salesGroup.dropDown === "PIPEFREEZE") ? "&market=PIPEFREEZE": "")+((salesGroup.dropDown === "DOMESTIC") || (salesGroup.dropDown === "HVAC-D") || (salesGroup.dropDown === "INDUSTRIAL-D") || (salesGroup.dropDown === "AUTOMOTIVE-D") ? "&territory=D" : "")+((salesGroup.dropDown === "INTERNATIONAL") ? "&territory=I" : "")}>   
        <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
        </a>
        <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text hidden={((newVal / (sum / 1000)) * 100) >= 10 ? true : false} x={ex + (cos >= 0 ? -1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="black">
          ${newVal}K {name}
        </text>
      </g>
    );
  };


  // index for hover, keeps track of the position of the hover on the pie
  const onPieEnter = (_, index) => {
    setDataThree({
      index,
    });
  };

  return (
    <>
      <Card style={{ width: "480px", height: "400px" }} className="shadow-sm">
        <Row>
          {graphError && (
            <div style={{ color: `red` }}>
              <p style={{ textAlign: "center", fontSize: "2.5rem" }}>{graphError.name}: {graphError.message}</p>
            </div>
          )}

          <h3 style={{ color: "black", textAlign: "center", fontSize: "1.5rem", marginTop: "6px" }}>{dataTwo.title}</h3>
          <h4 style={{ color: "black", textAlign: "center", fontSize: "1rem", marginTop: "-8px" }}>{dataTwo.description}</h4>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <label style={{ marginLeft: "5px" }}>Market:</label>
            <select style={{ marginLeft: "5px", width: "130px" }} value={salesGroup.dropDown} id="dropDown" name="dropDown" onChange={(e) => setSalesGroup({ ...salesGroup, dropDown: e.target.value })}>
              <option value="ALL">All</option>
              <option value="DOMESTIC">Domestic</option>
              <option value="INTERNATIONAL">International</option>
              {/* <option value="HVAC_IND-D">HVAC_IND-D</option> */}
              <option value="HVAC-D">HVAC-D</option>
              <option value="INDUSTRIAL-D">INDUSTRIAL-D</option>
              <option value="AUTOMOTIVE-D">Automotive-D</option>
              <option value="PIPEFREEZE">PipeFreeze</option>
            </select>
          </div>

          <div hidden={loading} style={{ height: "320px", display: "flex", justifyContent: "center" }}>
            <PieChart style={{ fontSize: "11px", fontWeight: "bold" }} width={600} height={320}>
              <Pie activeIndex={dataThree.index} activeShape={renderActiveShape} data={dataOne} dataKey={"sales"} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" label={renderCustomizedLabel} onMouseEnter={onPieEnter}>
                {dataOne?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Pie>
            </PieChart>
          </div>
        </Row>
      </Card>
    </>
  );
};

export default AccountClassificationPie;
